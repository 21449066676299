import React from 'react';


function Home() {
  return (
    <>
      <div className="relative">
        {/* Background Image */}
        <img
          className="w-full lg:h-screen h-[75vh] object-cover"
          src="/f.jpg"
          alt="background"
        />

        {/* Dark Overlay (Optional, to make the text more readable) */}
        <div className="absolute inset-0 bg-black opacity-50 " ></div>

        {/* Content */}
        <div className="absolute  inset-0 flex items-center  text-white px-6  w-full lg:w-1/2">
          <div className="max-w-7xl mx-auto text-center sm:px-5" data-aos="fade-right">
            {/* Static "D-Fitness" on the top */}
            <h1
              className="text-3xl lg:text-5xl font-bold text-left flex flex-col px-5 sm:px-0 md:px-20"
              style={{ fontFamily: '"Orelega One", serif' }}
            >
              <span>D-Fitness Club</span>
              {/* Adjusting text to keep "45 Days" on the same line for small screens */}
              <span className="block sm:hidden">Weight Loss for 10kg in 60 Days</span>
              <span className="hidden sm:block">
                Weight Loss for 10kg in
              </span>
              <span className="hidden sm:block">60 Days</span>
            </h1>
            <p className="font-extralight text-left px-5 sm:px-10 md:px-20 py-2">
              Online Only.
            </p>

            {/* Typing animation below "D-Fitness" */}
            <div className='text-start px-5 md:px-10 lg:px-20  py-0'>
              <a href="#program" className="inline-block mt-6 bg-red-600 text-white py-2 px-3  text-lg font-medium transition transform hover:scale-105">
                Contact Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
